<template>
  <div class="d-flex align-items-center" style="width: 100%; column-gap: 2rem;">
    <div style="flex-basis: 40%">
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="visibilityFilter"
        :options="visibilityOptions"
        :placeholder="'Visibility'" 
        class="w-100"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:visibilityFilter', val)"
      />
    </div>
    <div style="flex-basis: 60%">
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="statusFilter"
        :options="statusOptions"
        :placeholder="'Status'" 
        class="w-100"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:statusFilter', val)"
      />
    </div>
  </div>
  
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardActions,
    BCardText,
    vSelect,
  },
  props: {
    visibilityFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    visibilityOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
